 .main-heading {
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    vertical-align: middle;
    border-color: rgb(0, 0, 0);
    padding: .2em .5em;
    font-size: 40pt;
    font-family: Helvetica, Calibri, Arial, sans-serif;
/*    font-weight: bolder; */
    border-width: 2px;
    border-style: transparent;
    text-align: center;
    white-space: nowrap;
    border-radius: 5px;
  }

  .content-div {
    vertical-align: middle;
    text-align: center;
  }