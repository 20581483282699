
.main-div {
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    border-color: rgb(0, 0, 0);
    text-align: center;
    left:auto;
    right:auto;
    border-style: transparent;
    font-size: 20pt;
  }
