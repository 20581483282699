.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.consent-btn {
    margin-right: 30px;
    color: rgb(0, 0, 0);
    flex: 1 0 130px;
    background-color: rgb(139, 237, 79);
    border-color: transparent;
    padding: .2em .5em;
    font-size: 1.2em;
    font-family: Helvetica, Calibri, Arial, sans-serif;
    font-weight: bolder;
    border-width: 2px;
    border-style: solid;
    text-align: center;
    white-space: nowrap;
    border-radius: 5px;
    min-height: 30px;
  }
  
  .consent-btn:hover{
    background-color: rgb(177, 255, 117);
    cursor: pointer;
    text-decoration: underline;
  }
  
  .cookie-consent-bar {
    width: 100%;
    flex-wrap: wrap;
    position: fixed;
    align-items: center;
    text-align: center;
    bottom: 0;
    left: 0;
    right: 0;
    color: rgb(214, 214, 214);
    background-color: rgb(60, 64, 77);
    padding: 0.6em 1.8em;
    z-index: 9999;
  }

  .hide-cookie-consent-bar {
    width: 100%;
    flex-wrap: wrap;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    color: rgb(214, 214, 214);
    background-color: rgb(60, 64, 77);
    padding: 0.6em 1.8em;
    z-index: 9999;
    display: none
  }


.content {
    margin: 30px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .hidden-content {
    margin: 30px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: none
  }

  .main-heading {
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    vertical-align: middle;
    border-color: rgb(0, 0, 0);
    padding: .2em .5em;
    font-size: 40pt;
    font-family: Helvetica, Calibri, Arial, sans-serif;
/*    font-weight: bolder; */
    border-width: 2px;
    border-style: transparent;
    text-align: center;
    white-space: nowrap;
    border-radius: 5px;
  }

  .menu-btn-selected {
    text-decoration: underline;
    vertical-align: middle;
  }

  .go-to-kaliper-btn {
    text-decoration: underline;
    vertical-align: middle;
  }

  .hide-go-to-kaliper-btn {
    text-decoration: underline;
    vertical-align: middle;
    display: none
  }

  .login-form {
    margin: 30px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .hidden-login-form {
    margin: 30px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: none
  }


  
.conditional-menu {
  margin: 30px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display:inline-block;
}

.hidden-conditional-menu {
  margin: 30px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: none
}